@include breakpoint(medium) {

	#header {
		padding-top: 32%;
		
		img {
			max-width: 68%;
		}

		h1 {
			/*line-height: 1;*/
			margin-bottom: 1.4rem;
			margin-top: 2.4rem;
		}

		p {
			font-size: 1.1rem;
		}
		
	}

	#message {

		p {
			text-align: center;
		}
	}

	.downr {
		bottom: -3rem;
	}

	.ensure {
		text-align: center;
	}

} // end medium




@include breakpoint(large) {

	#header {
		padding-top: 18%;

		img {
			max-width: 48%;
		}

		#brand-statment {
			margin-top: -2rem;
		}
	}

	#message {
		
		.caution-tape {
			background-size: 6%;
		}
	}

	#services  {

		h3 {
			font-size: 1.4rem;
		}
	}

} // end large




@include breakpoint(xlarge) {

	p {
		font-size: 1.2rem;
	}

	.button {
		font-size: 1.2rem;
	}

	#header {
		padding-top: 8%;
	}

} // end xlarge
