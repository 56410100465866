@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
//@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
//@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
//@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
//@include foundation-sticky;
//@include foundation-title-bar;
//@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

body {
	
	section {
		&:last-child {
			border-bottom: 0;
		}
	}
}

section {
	position: relative;
	border-bottom: 1px solid rgba($primary-color,.2);
	padding: 4rem 0 5rem;

	.section-header {
		margin-bottom: 2rem;
	}

	.section-icon {
		text-align: center;
		font-size: 4rem;
		color: rgba($primary-color,.4);

		.fa-bullhorn {
			transform: rotate(-20deg);
		}
	}
}

p {
	letter-spacing: -.04rem;
}

#header {
	padding-top: 44%;
	padding-bottom: 1rem;
	border-bottom: 22px solid $primary-color;
	
	img {
		padding: 1rem 1.4rem;
	}

	#brand {

		.grid-container {
			padding: .2rem 1rem;
		}
		
		p {
			line-height: 1.2;
			border-top: 1px solid rgba($primary-color,.2);
			border-bottom: 1px solid rgba($primary-color,.2);
			padding-top: .3rem;
			padding-bottom: .48rem;
		}
	}

	#brand-statement {
		text-transform: uppercase;
	}

	h1 {
		padding: 1rem;
		text-transform: uppercase;

		span {
			text-transform: none;
		}
	}

	p {
		color: darken($dark-gray,12);
	}
}

#message {
	padding-top: 0;
	padding-bottom: 0;

	.section-header {
		/*padding-top: 3rem;*/
		/*padding-bottom: 3rem;*/
	}

	.caution-tape {
		background: url(../img/construction-pattern.gif);
		background-repeat: repeat-x;
		background-size: 24%;
		min-height: 5rem;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		opacity: .3;

		&:first-child {
			margin-bottom: 2rem;
		}
		&:last-child {
			margin-bottom: 0;
			margin-top: 2rem;
		}
	}

	h2 {
		/*font-size: 1.2rem;*/
	}
	
	p {
		text-align: left;
	}
}

#contact {
	background: rgba($primary-color,.3);

	h2 {
		/*margin-bottom: 2rem;*/
	}

	li {
		/*width: 46%;*/
		margin: 2px;

		a {

		}
	}
}

#services {

	h3 {
		font-size: 1.1rem;
		text-transform: uppercase;
		font-weight: bold;
		color: lighten($primary-color,5);
		border-bottom: 1px solid rgba($primary-color,.2);
	}

	#service-list {
		/*div {*/
			/*margin-bottom: 1rem;*/
		/*}*/
	}

	.service {
		/*color: darken($primary-color,12);*/
		font-size: .88rem;
		padding: .6rem;

		.service-content {
			padding: 1rem;
			border: 1px solid rgba($primary-color,.6);
			border-radius: 2px;

			p {
				color: darken($dark-gray,28);
				text-align: left;
			}
		}
	}
}

footer {
	background: $primary-color;
	background-image: url(../img/water-bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position:  center top;
	border-top: 1px solid #1FBDBC;
	/*position: fixed;*/
	bottom: 0;
	min-height: 4rem;
	display: block;
	width: 100%;
}

#subscribe-form {
	padding: 2rem;
}

/*\\\\\\\\\\\\\\\\*/ 
/*UTIL*/
/*\\\\\\\\\\\\\\\\*/ 
.downr {
	position: relative;
	bottom: -1rem;
	width: 100%;
	color: rgba($primary-color,.48);
	text-align: center;
	vertical-align: bottom;
	font-size: 2rem;
}

.ensure {
	line-height: 1;
	margin-top: 2rem;
	color: $dark-gray;
	text-align: left;

	strong {
		display: block;
		text-align: center;
		margin-bottom: 4px;
	}
}

.alert {
	/*color: #f00;*/
}


/*==================================
 * MEDIA QUERIES
==================================*/

@import'mediaq.scss';
